<template>
  <b-modal
    :visible="visible"
    scrollable
    centered
    size="md"
    title-class="text-center w-100"
    footer-class="flex-row-reverse justify-content-center"
    @ok="onCommentAdded"
    @hide="onHide"
    :ok-disabled="!comment"
  >
    <template #modal-title>{{ $t('comments.add-comment') }}</template>
    <b-form-group>
      <b-form-textarea v-model="comment" rows="3" max-rows="8" />
    </b-form-group>
    <template #modal-ok>{{ $t('comments.add') }}</template>
  </b-modal>
</template>

<script>
import { ok } from 'assert';

export default {
  props: {
    visible: Boolean,
    selectedContact: {
      type: Object,
      default: null,
    },
    selectedJobs: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      comment: '',
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        if (this.selectedContact) {
          this.comment = `${this.$t('comments.contacted')} ${
            this.selectedContact.firstName
          } ${this.selectedContact.lastName} : ${this.selectedContact.phone}`;
        }

        if (this.selectedJobs && this.selectedJobs.length) {
          this.comment += this.selectedJobs
            .map((job) => `\n${job.job_name} : `)
            .join('');
        }
      }
    },
  },
  methods: {
    onHide(bvModalEvent) {
      if (bvModalEvent.trigger !== 'ok') {
        this.$emit('close');
      }
    },
    onCommentAdded() {
      this.$emit('comment-added', this.comment);
    },
  },
};
</script>
