
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  BIconLaptop,
  BIconCalendar,
  BIconCheckSquare,
  BIconWrench,
  BIconCart,
  BIconTools,
  BIconExclamationTriangle,
  BIconSpeedometer2,
  BIconFileEarmarkText,
  BIconPower,
  BIconPenFill,
  BTooltip,
  BIconBootstrapReboot,
} from 'bootstrap-vue';

interface JobType {
  type: string;
  component: Vue.Component | null;
}

@Component({
  components: {
    BIconLaptop,
    BIconCalendar,
    BIconCheckSquare,
    BIconWrench,
    BIconCart,
    BIconTools,
    BIconExclamationTriangle,
    BIconSpeedometer2,
    BIconFileEarmarkText,
    BIconPower,
    BIconPenFill,
    BTooltip,
  },
})
export default class JobTypes extends Vue {
  @Prop({ required: true }) job!: any;

  id = '';

  created() {
    this.id = this.generateId();
  }

  get jobTypes(): JobType[] {
    const jobTypeKeys = [
      'is_remote',
      'async_appointment',
      'is_precheck',
      'is_installation',
      'is_hardware_sale',
      'is_maintenance',
      'is_defect_elimination',
      'is_meter_application',
      'is_planning',
      'is_activation',
      'is_customer_sign_required',
      'is_reclamation',
    ];

    return jobTypeKeys
      .filter((key) => this.job[key])
      .map((key) => ({
        type: key,
        component: this.getJobTypeIcon(key),
      }));
  }

  generateId() {
    const randomId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    return randomId;
  }

  getJobTypeIcon(jobType: string) {
    switch (jobType) {
      case 'is_remote':
        return BIconLaptop;
      case 'async_appointment':
        return BIconCalendar;
      case 'is_precheck':
        return BIconCheckSquare;
      case 'is_installation':
        return BIconWrench;
      case 'is_hardware_sale':
        return BIconCart;
      case 'is_maintenance':
        return BIconTools;
      case 'is_defect_elimination':
        return BIconExclamationTriangle;
      case 'is_meter_application':
        return BIconSpeedometer2;
      case 'is_planning':
        return BIconFileEarmarkText;
      case 'is_activation':
        return BIconPower;
      case 'is_customer_sign_required':
        return BIconPenFill;
      case 'is_reclamation':
        return BIconBootstrapReboot;
      default:
        return null;
    }
  }
}
