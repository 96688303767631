
import HtmlRich from '@gid/vue-common/components/HtmlRich.vue';
import RichTextEditor from '@gid/vue-common/components/RichTextEditor.vue';
import AdminPartnerTicketFilesSelector from '@gid/vue-common/components/inputs/AdminPartnerTicketFilesSelector.vue';
import AdminTicketFilesSelector from '@gid/vue-common/components/inputs/AdminTicketFilesSelector.vue';
import {
  LiveAgent,
  JobContact,
} from '@gid/vue-common/components/ticketing/interface/ticketing';
import axios from 'axios';
import { BModal } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { ITicketAttachment } from '@gid/models';

const MAX_PAYLOAD_SIZE_IN_MB = 50;

@Component({
  components: {
    RichTextEditor,
    'admin-partner-ticket-fs': AdminPartnerTicketFilesSelector,
    'admin-ticket-fs': AdminTicketFilesSelector,
    HtmlRich,
    Multiselect,
  },
  computed: mapGetters(['environment']),
})
export default class LiveAgentTicketNew extends Vue {
  @Prop({ default: false, required: true }) visible!: boolean;
  @Prop({ default: null, required: true }) jobContacts!: JobContact[];
  @Prop({ default: () => [], required: true }) jobNames!: string[];
  @Prop({ required: true }) context!: string;
  @Prop({ default: () => [], required: false }) jobs!: any[];

  @Ref('modal')
  readonly modal!: BModal;

  departments: any = [];
  senders: any = [];
  newTicket: LiveAgent.TicketInterface = {
    subject: '',
    departmentid: '',
    recipient: '',
    message: '',
    carbon_copies: '',
    blind_carbon_copies: '',
    status: 'C',
    mail_message_id: null,
    do_not_send_mail: 'N',
    use_template: 'Y',
    is_html_message: 'Y',
    custom_fields: [],
    tags: [],
    attachments: [],
    filePaths: [],
  };
  selectedAgent: any = null;
  error: string = '';
  showLoader: boolean = false;
  recipientContacts = {};
  ccContacts = {};
  selectedJobRole: string = '';
  selectedRecipient: any = null;
  selectedCC: any[] = [];
  showDiscardTicketModal = false;

  async created() {
    this.departments = await this.forDepartments();
    this.senders = await this.forSenders();
    this.recipientContacts = { ...this.jobContactsCategorizedData };
    this.ccContacts = { ...this.jobContactsCategorizedData };
  }
  //Commputed
  get departmentOptions() {
    if (!this.departments || !this.departments.length) {
      return [];
    }
    return this.departments.map((dfg: LiveAgent.Department) => {
      return { text: dfg.name, value: dfg.department_id };
    });
  }
  get senderOptions() {
    if (!this.senders || !this.senders.length) {
      return [];
    }
    return this.senders.map((sender: any) => {
      return { text: sender.name + ' - ' + sender.email, value: sender };
    });
  }
  get jobContactsCategorizedData() {
    const categorizedData = {};

    this.jobContacts.forEach((item) => {
      const role = item.jobRole || '';

      if (!categorizedData[role]) {
        categorizedData[role] = [];
      }

      categorizedData[role].push(item);
    });

    return categorizedData;
  }
  get jobRolesOptions() {
    return Object.keys(this.jobContactsCategorizedData).map((key) => ({
      text: key.toUpperCase(),
      value: key,
    }));
  }
  get job_view() {
    return this.jobs[0]; // If calling for a specific job
  }

  //Methods
  getJobIds() {
    if (this.context === 'admin-partner') {
      return this.jobs.map((job) => job.job_sfid);
    }
    return [this.job_view.sfid];
  }
  forDepartments = async () => {
    if (!this.departments || !this.departments.length)
      return this.fetchDepartments();
    return this.departments;
  };
  forSenders = async () => {
    if (!this.senders || !this.senders.length) return this.fetchSenders();
    return this.senders;
  };
  addNewTag(type: string, newTag: string) {
    if (!this.validateEmails(newTag)) {
      this.error = `${this.$t(`tickets.error.invalid-custom-recipient`)}`;
      return;
    }

    this.error = '';

    const tag = {
      apiId: Date.now().toString(),
      email: newTag,
      customTag: true,
    };

    if (type === 'recipient') {
      this.recipientContacts[this.selectedJobRole] = [
        ...this.recipientContacts[this.selectedJobRole],
        tag,
      ];
      this.selectedRecipient = tag;
    } else if (type === 'cc') {
      this.ccContacts[this.selectedJobRole] = [
        ...(this.ccContacts[this.selectedJobRole] || []),
        tag,
      ];
      this.selectedCC.push(tag);
    }
  }
  customContactLabel(item) {
    return item.customTag ? item.email : `${item?.firstName} ${item?.lastName}`;
  }
  async fetchDepartments(): Promise<LiveAgent.Department[]> {
    return axios
      .get(`/data-api/ticket/live-agent/departments`)
      .then((response) => {
        this.error = '';
        return response?.data;
      })
      .catch((error) => {
        this.error = `Error fetching departments`;
        return [];
      });
  }
  async fetchSenders(): Promise<any> {
    return axios
      .get(`/data-api/ticket/live-agent/agents`)
      .then((response) => {
        this.error = '';
        return response?.data;
      })
      .catch((error) => {
        this.error = `Error fetching senders`;
        return [];
      });
  }
  attachment(data: { files: ITicketAttachment[]; filePaths: string[] }) {
    this.newTicket.attachments = data.files;
    this.newTicket.filePaths = data.filePaths;
  }
  async onCreateTicket() {
    const leaveOnError = (error) => {
      this.error = error;
      return;
    };

    if (this.newTicket.departmentid === '')
      return leaveOnError(this.$t(`tickets.error.department-required`));
    if (this.selectedAgent === null)
      return leaveOnError(this.$t(`tickets.error.sender-required`));
    if (!this.selectedRecipient?.email)
      return leaveOnError(this.$t(`tickets.error.recipient-required`));
    if (
      this.newTicket.blind_carbon_copies !== '' &&
      !this.validateEmails(this.newTicket.blind_carbon_copies)
    )
      return leaveOnError(this.$t(`tickets.error.invalid-bcc-email`));
    if (this.newTicket.subject === '')
      return leaveOnError(this.$t(`tickets.error.subject-required`));
    if (this.newTicket.message === '')
      return leaveOnError(this.$t(`tickets.error.message-required`));

    this.newTicket.recipient = this.selectedRecipient.email || '';

    this.newTicket.carbon_copies = this.selectedCC
      .map(({ email }) => email)
      .join(',')
      .trim();

    const payload = {
      ...this.newTicket,
      agentid: this.selectedAgent.id,
      useridentifier: this.selectedAgent.email,
      jobIds: this.getJobIds(),
    };

    const sizeCheck = this.validatePayloadSize(payload, MAX_PAYLOAD_SIZE_IN_MB);
    if (!sizeCheck.isValid) {
      return leaveOnError(
        this.$t(`tickets.error.payload-too-large`, {
          size: sizeCheck.sizeInMB.toFixed(2),
          max: MAX_PAYLOAD_SIZE_IN_MB,
        }),
      );
    }

    try {
      this.showLoader = true;
      this.error = '';
      await axios.post(`/data-api/ticket`, payload);
      this.onSuccess();
    } catch (error) {
      this.error = 'Error occured while creating ticket!';
    } finally {
      this.showLoader = false;
    }
  }
  validateEmails(emailsString: string) {
    const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emails = emailsString.split(',');

    return emails.every((email: string) => regEx.test(email.trim()));
  }
  onSuccess() {
    this.ticket_created();
    this.close();
    this.modal.hide();
    this.clear();
  }
  onModalClose() {
    this.clear();
    this.showDiscardTicketModal = false;
    this.modal.hide();
    this.close();
  }
  clear() {
    this.newTicket.departmentid = '';
    this.newTicket.recipient = '';
    this.newTicket.carbon_copies = '';
    this.newTicket.blind_carbon_copies = '';
    this.newTicket.subject = '';
    this.newTicket.message = '';
    this.newTicket.attachments = [];
    this.selectedRecipient = null;
    this.selectedCC = [];
    this.selectedJobRole = '';
    this.error = '';
    this.selectedAgent = null;
  }
  validatePayloadSize(payload, maxSizeInMB = 50) {
    const payloadString = JSON.stringify(payload);
    const payloadSizeInBytes = new TextEncoder().encode(payloadString).length;
    const payloadSizeInMB = payloadSizeInBytes / (1024 * 1024); // Convert to MB

    const isValid = payloadSizeInMB <= maxSizeInMB;

    return { isValid, sizeInMB: payloadSizeInMB };
  }

  @Emit('ticket-created')
  ticket_created() {
    return true;
  }
  @Emit('close')
  close() {
    return true;
  }
  @Watch('selectedJobRole')
  onSelectedjobRole() {
    this.selectedRecipient = null;
    this.selectedCC = [];
  }
  @Watch('jobNames')
  onJobNames() {
    if (this.jobNames.length > 0)
      this.jobNames.forEach((name, index) => {
        this.newTicket.custom_fields[index] = {
          code: '01',
          value: name,
        };
      });
  }
}
