<template>
  <b-modal
    :visible="visible"
    scrollable
    centered
    :hide-footer="contactList && contactList.length === 0"
    @hide="onHide"
    @ok="onCallInitiated"
    :ok-disabled="!selectedContact"
    title-class="text-center w-100"
    footer-class="flex-row-reverse justify-content-center"
    cancel-variant="outline-primary"
  >
    <template #modal-title>Who do you want to call?</template>
    <template #modal-ok>Call now</template>
    <b-form-group
      v-if="contactList && contactList.length > 0"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio
        v-for="c in contactList"
        :key="c.id"
        v-model="selectedContact"
        :aria-describedby="ariaDescribedby"
        name="select-contact"
        :value="c"
        class="py-1"
      >
        {{ c.lastName }} {{ c.firstName }} : {{ c.phone }}
      </b-form-radio>
    </b-form-group>
    <span v-else class="text-center d-inline-block w-100 py-3">
      There are no contacts available for this account.
    </span>
  </b-modal>
</template>

<script>
export default {
  props: {
    contactList: {
      type: Array,
      default: () => [],
    },
    visible: Boolean,
  },
  data() {
    return {
      selectedContact: null,
    };
  },
  watch: {
    contactList: {
      handler(value) {
        if (value.length === 1) {
          this.selectedContact = value[0];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onHide(bvModalEvent) {
      this.$emit('hide');
    },
    onCallInitiated() {
      this.$emit('call-initiated', this.selectedContact);
    },
  },
};
</script>
